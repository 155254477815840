import React, {Fragment, useEffect, useState} from "react";
import { useAuthFetch } from './useAuthFetch';
import { getApiUrl } from "./authConfig";
import { observer, useLocalObservable } from 'mobx-react';
import { toJS } from 'mobx';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { requestProductData } from "./hooks/Product";
import { requestProductFamilyData } from "./hooks/ProductFamily";
import { requestTemplateCheckedData } from "./hooks/Template";

import { AssetObject, TemplateObject } from "./common/types";
import { ProductObject } from "./common/types";
import { ProductFamiliesObject } from "./common/types";

import { AssetStatuses } from "./common/typeOptions";
import { DeploymentPhases } from "./common/typeOptions";
import { CommissioningItems } from "./common/typeOptions";

import "./assets/css/modal.css";

export type CreateAssetDialogProps = {
    isOpen?: boolean
    onSubmit?: (status: string)=>void
    initialData?: AssetObject
    buildingId?: string
    //productList?: Array<any>
    //productFamilies?: Array<any>
    defaultTemplates?: Array<any>
    productDropDown?: Array<any>
    assetSuccess(successStatus: string): void
} 

export const CreateAssetDialog = observer((props: CreateAssetDialogProps)=> {
    const authFetch = useAuthFetch(); 
    const [modalOpen, setModalOpen] = useState(false);
    const [validated, setValidated] = useState(false);
    const [submitPlus, setSubmitPlus] = useState(false);
    const [submitButtonDisplay, setSubmitButtonDisplay] = useState(false);
    const [productData, setProductData] = useState<ProductObject[]>([]);
    const [productFamilyData, setProductFamilyData] = useState<ProductFamiliesObject[]>([]);
    const [templateData, setTemplateData] = useState<TemplateObject[]>([]);

    let isOpen = props.isOpen || false;
    let existingId = props.initialData == null? "": props.initialData.id;
    let buildingId = props.buildingId == null?  "": props.buildingId;

    //var productList = props.productList || []; 
    //var productFamilies = props.productFamilies || [];
    //var defaultTemplates = props.defaultTemplates || [];
    var productDropDown = props.productDropDown || [];

    /*setProductFamilyData(productFamilyData.filter(it => {
        let productIdx = productData.findIndex(prd => prd.productFamily == it.id);
        if (productIdx == -1) {
            return false;
        }
        return true;
    }));*/

    //productList = productList.sort((a, b) => a.productFamily.localeCompare(b.productFamily));

    const handleModalSuccessClose = () => { handleClose() };

    const handleModalErrorClose = () => { setModalOpen(false); };

    var initialData = props.initialData || {
        deploymentPhase: "Warehouse", 
        assetType: "Water", 
        status: "Not Commissioned", 
        commissioning: "False", 
        model: "",
        manufacturer: "", 
        productCode: "",
        productFamilyId: "",
        serialNumber: "",
        commSerialNumber: "",
        defaultTemplate: ""
    }

    const formData = useLocalObservable(()=> ({ 
        deploymentPhase: "Warehouse", 
        assetType: "Water", 
        status: "Not Commissioned", 
        commissioning: "False", 
        model: "",
        manufacturer: "", 
        productCode: "",
        productFamilyId: "",
        serialNumber: "",
        commSerialNumber: "",
        defaultTemplate: ""
    }))

    const requestInitialData = async () => {
        await requestProductData(authFetch).then(res => { return res; }).then((data) => { setProductData(data); });
        await requestProductFamilyData(authFetch).then(res => { return res; }).then((data) => { setProductFamilyData(data); });
        await requestTemplateCheckedData(authFetch, true).then(res => { return res; }).then((data) => { setTemplateData(data); });
    }

    const setInitialData = () => {
        requestInitialData();

        formData.deploymentPhase = initialData.deploymentPhase;
        formData.assetType =  initialData.assetType;
        formData.status =  initialData.status;
        formData.commissioning = initialData.commissioning;
        formData.model =  initialData.model;
        formData.manufacturer =  initialData.manufacturer;
        formData.productCode = initialData.productCode;
        formData.productFamilyId = initialData.productFamilyId;
        formData.serialNumber = initialData.serialNumber;
        formData.commSerialNumber = initialData.commSerialNumber;
        formData.defaultTemplate = initialData.defaultTemplate;

        if (initialData.productCode == "" && productData.length != 0 ) {
            let prd = productData[0]; 
            formData.assetType =  prd.assetType;
            formData.commissioning = prd.commissioning;
            formData.model =  prd.model;
            formData.manufacturer =  prd.manufacturer;
            formData.productCode = prd.productCode;
            formData.productFamilyId = prd.productFamily;
            formData.defaultTemplate = prd.defaultTemplate;
        }
    }

    //useEffect(() => { setInitialData() }, [props.initialData]);
    useEffect(() => { setInitialData() }, []);

    const handleClose = () => {
        if (props.onSubmit != null) {
            event.preventDefault();
            event.stopPropagation();
            props.onSubmit("cancel");
        }
    };

    const handleSubmit = async (submitPlus: boolean) => {
        let data = toJS(formData);

        let method = existingId == ""? "POST": "PUT";
        let url = getApiUrl("assets"); 

        if(existingId != "") {
            url = `${url}/${existingId}`
        }

       try {
            let fetchResponse = await authFetch(method, url, { ...data, buildingId: buildingId });
           if (fetchResponse.status != 409) {
               setSubmitButtonDisplay(false);
                if (method === "POST") {
                    props.assetSuccess("Asset with Serial #" + data.serialNumber + " has been added");
                } else {
                    props.assetSuccess("Asset with Serial #" + data.serialNumber + " has been updated");
                }

                if (submitPlus) {
                    formData.serialNumber = "";
                    const input = document.getElementById("serial-input");
                    input.focus();
                } else {
                    if (props.onSubmit != null) {
                        props.onSubmit("success");
                        handleClose();
                    }
                }
            } else {
                setModalOpen(true);
            }
        } catch (error) {
            console.log('error:' + error);
        }
    }

    const title = existingId == ""? "Create Asset": "Update Asset";

    const handleProductSelect = (productCode: string) => {
        formData.productCode = productCode;

        let selectedProduct = productData.find(it => it.productCode == productCode);
        let selectedProductFamily = productFamilyData.find(it => it.id == selectedProduct.productFamily); 
        if(selectedProduct != null) {
            formData.assetType =  selectedProduct.assetType;
            formData.commissioning = selectedProduct.commissioning;
            formData.model =  selectedProduct.model;
            formData.manufacturer =  selectedProduct.manufacturer;
            formData.productCode = selectedProduct.productCode;
            formData.productFamilyId = selectedProduct.productFamily;
            formData.defaultTemplate = selectedProduct.defaultTemplate != "" ? selectedProduct.defaultTemplate : selectedProductFamily.defaultTemplate;
        }
    }

    const handleProductFamilySelect = (pfamily: string) => {
        formData.productFamilyId = pfamily;

        let prd = productData.find(it => it.productFamily == pfamily); 

        if(prd != null) {
            formData.productCode = prd.productCode;
            handleProductSelect(prd.productCode);
        }
    }

    const serialNumberKeyPress = (event) => {
        if (event.key === 'Enter') {
            console.log("ENTER");
            //handleSubmitForm(event);
        }
    }

    const handleSubmitForm = (event) => {
        setSubmitButtonDisplay(true);
        if (event.nativeEvent.submitter.name != "cancelbtn") {
            const form = event.currentTarget;
            if (form.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
                setValidated(true);
            } else {
                setValidated(false);
                if (!submitPlus) {
                    handleSubmit(false);
                    event.preventDefault();
                } else {
                    handleSubmit(true);
                    event.preventDefault();
                    event.stopPropagation();
                }
            }
        }
    };

    const templateChange = (event) => {
        formData.defaultTemplate = event.target.value;
    }

    const statusChange = (event) => {
        formData.status = event.target.value;
    }

    const commissioningChange = (event) => {
        formData.commissioning = event.target.value;
    }

    const deploymentPhaseChange = (event) => {
        formData.deploymentPhase = event.target.value;
    }

    const familyChange = (event) => {
        handleProductFamilySelect(event.target.value);
    }

    const productChange = (event) => {
        handleProductSelect(event.target.value);
    }

    const serialNumberChange = (event) => {
        let value = event.target.value.replace(/(\r\n|\n|\r)/gm, ",");
        formData.serialNumber = value;
    }

    return (
        <>
        <Modal show={isOpen} size="lg">
                <Form noValidate validated={validated} onSubmit={handleSubmitForm}>
                <Modal.Header>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div style={{ marginBottom: '12px' }}>Please enter the information about the asset</div>

                    <div className="dropcontainer">
                        <div className="d-grid gap-2">
                            <div className="dropdown-label">Product Family</div>
                            <Form.Select required className={"d-grid"} size="lg" value={formData.productFamilyId} onChange={familyChange}>
                                <option value="" disabled></option>
                                    {productFamilyData?.map((family, index) => {
                                    return (<option key={index} value={family.id}>{family.name}</option>);
                                })}
                            </Form.Select>
                        </div>
                    </div>

                        <div className="dropcontainer">
                            <div className="d-grid gap-2">
                                <div className="dropdown-label">Product Code</div>
                                <Form.Select required className={"d-grid"} size="lg" value={formData.productCode} onChange={productChange}>
                                    <option key="empty" value="" disabled></option>
                                    {
                                        productDropDown.map((data, index, arr) => {
                                            const previousItem = arr[index - 1];
                                            return (previousItem == null || previousItem.productFamilyName != data.productFamilyName ?
                                                <Fragment key={"prod-" + index}>
                                                    <option key={data.productFamilyName} disabled style={{ color: "lightblue", fontWeight: "bold", marginLeft: "4px" }}>{data.productFamilyName}</option>
                                                    <option key={index} value={data.productCode} onClick={() => { handleProductSelect(data.productCode); }}>&emsp;{data.productCode}</option>
                                                </Fragment>
                                                :
                                                <option key={index} value={data.productCode} onClick={() => { handleProductSelect(data.productCode); }}>&emsp;{data.productCode}</option>
                                            );
                                        })
                                    }
                                </Form.Select>
                            </div>
                        </div>

                        <div className="dropcontainer">
                            <div className="d-grid gap-2">
                                <div className="dropdown-label">Serial Number</div>
                                <Form.Group>
                                    <Form.Control required id="serial-input" onKeyDown={serialNumberKeyPress} className="modal-input" size="lg" as="textarea" placeholder={formData.serialNumber} value={formData.serialNumber} onChange={e => { serialNumberChange(e) }} />
                                    <Form.Control.Feedback type="invalid">Please add a serial number.</Form.Control.Feedback>
                                </Form.Group>
                            </div>
                        </div>

                        <div className="dropcontainer">
                            <div className="d-grid gap-2">
                                <div className="dropdown-label">Deployment Phase</div>
                                <Form.Select className={"d-grid"} size="lg" value={formData.deploymentPhase} onChange={deploymentPhaseChange}>
                                    <option value="" disabled></option>
                                    {DeploymentPhases.map((phase, index) => {
                                        return (<option key={index} value={phase}>{phase}</option>);
                                    })}
                                </Form.Select>
                            </div>
                        </div>

                        <div className="dropcontainer">
                            <div className="d-grid gap-2">
                                <div className="dropdown-label">Commissioning</div>
                                <Form.Select className={"d-grid"} size="lg" value={formData.commissioning} onChange={commissioningChange}>
                                    <option value="" disabled></option>
                                    {CommissioningItems.map((commission, index) => {
                                        return (<option key={index} value={commission}>{commission}</option>);
                                    })}
                                </Form.Select>
                            </div>
                        </div>

                        <div className="dropcontainer">
                            <div className="d-grid gap-2">
                                <div className="dropdown-label">Status</div>
                                <Form.Select className={"d-grid"} size="lg" value={formData.status} onChange={statusChange}>
                                    <option value="" disabled></option>
                                    {AssetStatuses.map((status, index) => {
                                        return (<option key={index} value={status}>{status}</option>);
                                    })}
                                </Form.Select>
                            </div>
                        </div>

                        <div className="dropcontainer">
                            <div className="d-grid gap-2">
                                <div className="dropdown-label">Default Templates</div>
                                <Form.Select className={"d-grid"} size="lg" value={formData.defaultTemplate} onChange={templateChange}>
                                    <option value="" disabled></option>
                                    {templateData?.map((template, index) => {
                                        return (<option key={index} value={template.name}>{template.name} (v{template.version})</option>);
                                    })}
                                </Form.Select>
                            </div>
                        </div>

                    <div className="dropcontainer">
                        <div className="d-grid gap-2">
                            <div className="dropdown-label-disabled">Asset Type</div>
                            <Form.Control className="modal-input-disabled" size="lg" type="text" placeholder={formData.assetType} aria-label="Disabled input example" readOnly />
                        </div>
                    </div>

                    <div className="dropcontainer">
                        <div className="d-grid gap-2">
                            <div className="dropdown-label-disabled">Model</div>
                            <Form.Control className="modal-input-disabled" size="lg" type="text" placeholder={formData.model} aria-label="Disabled input example" readOnly />
                        </div>
                    </div>

                    <div className="dropcontainer">
                        <div className="d-grid gap-2">
                            <div className="dropdown-label-disabled">Manufacturer</div>
                            <Form.Control className="modal-input-disabled" size="lg" type="text" placeholder={formData.manufacturer} aria-label="Disabled input example" readOnly />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                        <button name="cancelbtn" onClick={handleClose} className="btn btn-light">Cancel</button>
                        <button disabled={submitButtonDisplay} name="submitbtn" id="submitButton" className="btn btn-light">Submit</button>
                        {title.includes("Create") ?
                            <div style={{ overflow: "hidden", width: "150px" }}>
                                <div style={{ float: "left", fontSize: "17px", width: "84px", lineHeight: "48px" }} >Submit +</div>
                                <div className="form-check form-switch">
                                    <Form.Check className="form-control-lg" type="switch" id="custom-switch" defaultChecked={submitPlus} onChange={e => setSubmitPlus(e.target.checked)} label="" style={{ overflow: "hidden" }} />
                                </div>
                        </div>

                        : ""}
                </Modal.Footer>

                <Modal className="modalStyle" show={modalOpen} centered>
                    <Modal.Header>
                        <Modal.Title>Error</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>With the given manufacturer, product code and serial number already exists.</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleModalErrorClose}>Close</Button>
                    </Modal.Footer>
                </Modal>
            </Form>
        </Modal>
        </>
    );
});
